import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import {
  FaUserAlt,
  FaInfoCircle
} from "react-icons/fa";
import {
  api_get_voucher_order,
  api_pay_voucher,
  api_pay_voucher_check,
  api_get_windcave_session_voucher,
  api_park_cache
} from "../../api/api";
import { numberSpinner, colorSetProperty, setGoogleFont, setTitle } from "../../helpers/helpers";
import {
  formatCVC,
  formatCreditCardNumber,
  formatExpirationDate
} from "../OrderProcess/step5/utils";
import StepperPayLink from "../Layout/StepperPayLink";
import WindcaveCard from "../OrderProcess/step6/WindcaveCard";
import axiosRetry from 'axios-retry';

let numberError = "",
    expireError = "",
    cvcError = "",
    timeSpinner = "";

export default class VoucherPaymentLink extends Component {
  state = {
    isLoading: true,
    nameClass: "",
    numberClass: "",
    expireClass: "",
    expireError: "",
    focused: "",
    success_page: true,
    checkTerm: false,
    redirectConfirmPage: false,
    redirectToIndex: false,
    // booking data
    pay_amount: 0,
    voucher_order_id: null,
    voucher_data: {},
    voucher_name: '',
    voucher_date: '',
    voucher_park: '',
    voucher_ids: [],
    voucher_total_amount: 0.00,
    voucher_amount_paid: 0.00,
    voucher_amount_outstanding: 0.00,
    // park
    parks: null,
    updatedFont: false,
    updatedTitle: false,
    windcaveLink: undefined,
    previousWindcaveLink: undefined,
    park_id: null,
    windcaveSession: null,
    previousWindcaveSession: false,
    error: {
      errorCode: false,
      errorMsg: false,
      errorDetail: false,
      counter: 0,
    },
    isIOS: false,
  }

  componentWillMount(){
    const reloadCount = sessionStorage.getItem('reloadCount');
    if(reloadCount < 1) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  }

  componentDidMount() {
    // initiate data
    let params = new URLSearchParams(this.props.location.search);
    let voucher_order_id = params.get("voucher_order_id");
    let amount = params.get('amount');

    if (voucher_order_id) {
      // Hit to backend
      this.postToApi(
        api_get_voucher_order, {
        voucher_order_id: parseInt(voucher_order_id)
      }).then(({ data }) => {
        let { result } = data;
        let voucher_ids = []
        result.voucher_details.map((voucher, index) => {
            voucher_ids.push(voucher.id)
        })

        amount = amount ? amount : result.amount_due
        console.log(result);
        if ((result.amount_due).toFixed(2) <= 0 && result.state == 'confirmed') {
          alert("Voucher " + result.voucher_order_name + " is fully paid");
          this.setState({ redirectToIndex: true });
        }
        else if (result.state != 'draft') {
          alert("Voucher " + result.voucher_order_name + " is a " + result.state + " voucher order.");
          this.setState({ redirectToIndex: true });
        }
        else if (amount > result.amount_due){
          alert(result.voucher_order_name + ":\nAmount to pay is more than amount due");
          this.setState({ redirectToIndex: true });
        }
        else if (amount >= 1) {
          // set to state
          this.setState({
            voucher_data: result,
            voucher_order_id: parseInt(voucher_order_id),
            voucher_name: result.voucher_order_name,
            voucher_date: result.create_date,
            voucher_park: result.park[1],
            voucher_total_amount: (result.amount).toFixed(2),
            voucher_amount_paid: (result.amount_paid).toFixed(2),
            voucher_amount_outstanding: (result.amount_due).toFixed(2),
            pay_amount: parseFloat(amount).toFixed(2),
            park_id: result.park[0],
            voucher_ids: voucher_ids
          }, () => {
            this.getParkData(result.park[0]);
            this.get_windcave_session();
          });
        }else {
          alert(`voucher order id '${voucher_order_id}' already paid`);
          this.setState({ redirectToIndex: true });
        }
      })
    }else {
      alert("there's no voucher order id");
      this.setState({ redirectToIndex: true });
    }
  }

  fetchNewSession = () => {
    this.postToApi(api_get_windcave_session_voucher, {
      total_amount: parseFloat(this.state.pay_amount),
      voucher_order_id: this.state.voucher_order_id,
      previous_windcave_session: this.state.previousWindcaveSession,
      windcave_session: this.state.windcaveSession,
      exp_from_record: false,
      voucher_code: this.state.voucher_ids,
      full_page: true,
      payment_method: ['applepay', 'card'],
    })
    .then(({ data }) => {
      console.log(data)
      if ("result" in data) {
        
        if (data.result === false) {
          window.location.reload()
        }
        window.localStorage.setItem('parks', JSON.stringify(this.state.parks))
        window.localStorage.setItem('pay_amount', parseFloat(this.state.pay_amount))
        const windcaveRef = data["result"]["links"].filter(link => link['method'] === 'REDIRECT')[0]['href']
        window.location.href = windcaveRef
      }
    })
  }
    
  get_windcave_session = (declined) => {
    if (this.state.error.counter == 2) {
      window.location.reload()
    }
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let hasSafari = userAgent.match(/Safari/i);
    let hasOtherBrowser = /Chrome|CriOS|OPiOS|mercury|FxiOS|Firefox|GSA/i.test(userAgent)
    let isAppleVendor = /Apple/i.test(navigator.vendor);
    let iosDevice = /iPad|iPhone|iPod/.test(userAgent)
    let isMac = /Macintosh/.test(userAgent)
    let pay_method = ['card']
    // Apple Pay only works on ios device and safari
    if ( (isMac || iosDevice) && hasSafari && isAppleVendor && !hasOtherBrowser) {
      this.setState({isIOS: true})
      pay_method.push('applepay');
    }
    // Google pay works on most of the android, browser and mac
    if (!iosDevice || ( isMac && hasOtherBrowser )) {
      pay_method.push('googlepay');
    }

    this.setState({
      previousWindcaveSession: this.state.windcaveSession
    });

    let state = { ...this.state };
    this.postToApi(
        api_get_windcave_session_voucher, {
        total_amount: parseFloat(this.state.pay_amount),
        voucher_order_id: this.state.voucher_order_id,
        previous_windcave_session: this.state.previousWindcaveSession,
        windcave_session: this.state.windcaveSession,
        voucher_code: this.state.voucher_ids,
        exp_from_record: false,
        payment_method: pay_method,
    })    
    .then(({ data }) => {
      if ("result" in data) {

        if (data.result === false || data.result == 'expired') {
          alert("Session has expired, you have not been charged");
          window.location.reload()
        }

        console.log(data["result"]["id"]);
        const windcaveRef = data["result"]["links"].filter(link => link['method'] === 'REDIRECT')[0]['href']
        const currentWindcaveLink = this.state.windcaveLink;
        if ("error" in data['result']) {
          this.setState({
            error: {
              errorCode: data['result']['error']['errorCode'],
              errorMsg: data['result']['error']['errorMsg'],
              errorDetail: data['result']['error']['errorDetail'],
              counter: this.state.error.counter + 1
            }
          });
          console.log(this.state.error.counter)
        }
        this.setState({
          windcaveLink: windcaveRef,
          previousWindcaveLink: currentWindcaveLink,
          windcaveSession: data["result"]["id"]
        });
      }
    })
  }

  componentDidUpdate() {
    // Call Customize
    if (this.state.parks) {
      this.customizeColor(this.state.parks);
      if (this.state.updatedTitle) {
        setTitle(this.state.parks.name);
        this.setState({ updatedTitle: true })
      }
      // - custom font -
      let snipFont = this.state.parks.t_google_font_snippets + ' <style> body {' + this.state.parks.t_font_to_use + '} </style>';
      if (snipFont && this.state.updatedFont == false) {
        setGoogleFont(snipFont);
        this.setState({ updatedFont: true })
      }
    }
  }

  // Tools
  // - Post Api Data Handler -
  postToApi = (url, param) => {
    let params = {
      jsonrpc: "2.0",
      method: "call",
      id: null,
      params: param
    };
    return axios.post(url, params, {
      headers: { "Content-Type": "application/json;charset=utf-8" }
    });
  }

  // - Get Park Data -
  getParkData = (park_id) => {
      axios.get(api_park_cache).then(({ data }) => {
        let { result } = data;
        let res = result.filter(park => park.id == park_id);
        this.setState({ parks: res}); 
      }).catch(() => {
        alert(`park id '${park_id}' not found`);
        this.setState({ redirectToIndex: true });
    })
  }
  
  customizeColor(park) {
    const parkData = park[0];
    // Customize color
    const colors = {
        'color1': parkData.t_color1,
        'color2': parkData.t_color2,
        'color3': parkData.t_color3,
        'color4': parkData.t_color4,
    }
    colorSetProperty(colors);
  }
  // - save payment -
  savePayment = payment => {

    this.state.error.errorCode = false;
    this.state.error.errorMsg = false;
    this.state.error.errorDetail = false;

    this.toggleLoading(true);

    const client = axios.create({ baseURL: api_pay_voucher_check, headers: {'Content-Type': 'application/json'} });
    axiosRetry(client, { retries: 45, retryDelay: (retryCount) => {
      return retryCount * 750;
    } });
    let parameters = {
      voucher_order_id: this.state.voucher_order_id,
      windcave_session: this.state.windcaveSession,
    };

    client.get("/check", { params: parameters }).then( ({data}) => {
        if (data.success) {
          console.log("RETURN HIT", data)
          let error = false
          let payment_info = false
          let amount_info = false
          let voucher_list = []
          if (data.error) {
            this.setState({success_page: false})
            error = data.error.data.message
          } else {
            payment_info = '{"id":"' + data.payments[0].id + '", "name":"'+ data.payments[0].name + '", "transaction_number":"' + data.payments[0].transaction_number +'"}'
            amount_info = '{"total_amount":"' + data.total_amount + '", "amount_paid":"' + data.amount_paid + '", "amount_due":"' + data.amount_due +'", "amount":"' + data.amount +'"}'
              data.voucher_ids.map((voucher, index) => {
                  voucher_list.push(voucher)
              })
          }
          // set to localstorage the response and booking data
          let val = '{"id": "'+ this.state.voucher_order_id +'", "name": "'+ this.state.voucher_name +'","pay_amount":"' + this.state.pay_amount+'","error":"'+error+'","success_page":"' + this.state.success_page +'"}'
          window.localStorage.setItem('voucher_data', val)
          window.localStorage.setItem('voucher_ids', JSON.stringify(voucher_list))
          window.localStorage.setItem('payment_info', payment_info)
          window.localStorage.setItem('amount_info', amount_info)
          window.localStorage.setItem('parks', JSON.stringify(this.state.parks))
          console.log("val:", val);

          this.setState({redirectConfirmPage: true})
        } 
    });
  };

  // - spinner -
  spinnerTimeout() {
    timeSpinner = setTimeout(
      function() {
        this.setState({isLoading: false})
      }
      .bind(this),
      numberSpinner()
    );
  }
  stopSpinnerTimout() {
    clearTimeout(timeSpinner)
  }

  // - loading -
  toggleLoading(value) {
    this.setState({ isLoading: value });
    if (value) {
      this.spinnerTimeout()
    } else {
      this.stopSpinnerTimout()
    }
  }

  // - redirect to index page -
  redirectToIndex = () => {
    if (this.state.redirectToIndex) {
      return <Redirect to="/" />;
    } else if (this.state.redirectConfirmPage) {
      return (
        <Redirect
          to={{
              pathname:
              "/voucher_payment_status/" + this.state.voucher_order_id,
              state: {success_page: this.state.success_page}
          }}
        />
      );
    }
  }

  // Component Handler
  // - input change handler -
  handleInputChange = ({ target }) => {
    // Retrieve Data
    let { name, value } = target;

    if (name === 'name') {
      if (value.length > 0) {
        this.setState({ nameClass: 'not-empty' });
      }else {
        this.setState({ nameClass: '' });
      }
    } else if (name === "number") {
      value = formatCreditCardNumber(value);
      if (value.length > 0) {
        this.setState({ numberClass: "not-empty" });
      }else {
        this.setState({ numberClass: "" });
      }
    } else if (name === "expiry") {
      value = formatExpirationDate(value);
      if (value.length > 0) {
        this.setState({ expiryClass: "not-empty" });
      } else {
        this.setState({ expiryClass: "" });
      }
    }else if (name === "cvv") {
      value = formatCVC(value);
      if (value.length > 0) {
        this.setState({ cvcClass: "not-empty" });
      } else {
        this.setState({ cvcClass: "" });
      }
    }

    this.setState({ [name]: value });
  }

  // - input focus handler -
  handleInputFocus = ({ target }) => {
    this.setState({ focused: target.name });
  }

  handleCheckbox = () => {
    this.setState({ checkTerm: !this.state.checkTerm });
  }

  // Sub Component Rendering
  // - credit card info -
  creaditInfo() {
    return (
      <React.Fragment>
        <div style={{paddingLeft: '25px'}}>
          <WindcaveCard
            type='booking_payment'
            cardClass="card-windcave"
            WindcaveCardCollapse={true}
            data={this.state.voucher_order_id}
            actionCollapse={this.ActionCollapseState}
            parks={this.state.park_id}
            getNewSession={this.fetchNewSession}
            action={this.savePayment}
            isIOS={this.state.isIOS}
            totalAmount={this.state.pay_amount}
            actionWindcave={this.get_windcave_session}
            enableSubmit={this.state.profileEnableSubmit}
            windcaveLink={this.state.windcaveLink}
            previousWindcaveLink={this.state.previousWindcaveLink}
            toggleLoading={this.toggleLoading.bind(this)}
            error={this.state.error}
          />
        </div>
      </React.Fragment>
    ) 
  }

  // - booking details -
  renderBookingDetails() {
    // Initiate variable and data
    let items = [];
    let { booking_detail } = this.state.voucher_data

    if (booking_detail !== undefined) {
      // render product name
      booking_detail.forEach((book, index) => {
        if (index > 0) {
          // make it indent
          items.push( <div key={`${index}-empty`} className="col-md-6 price-qty text-right"></div> )
        }
        items.push(
          <div key={index} className="col-md-6 price-qty text-right">
            <span className="label-detail text-left">
              {book.name}
            </span>
          </div>
        )
      });
    }
    // return result
    return items;
  }

  render() {
    let step = [
      true,
      false
    ];
    let {
      voucher_name,
      voucher_date,
      voucher_park,
      voucher_total_amount,
      voucher_amount_paid,
      voucher_amount_outstanding,
      pay_amount,
      parks
    } = this.state;

    return (
      <div className={this.state.isLoading === true ? 'loading': ''}>
        {this.redirectToIndex() /* redirecting to index */}
        <StepperPayLink step={step} parks={parks} />
        <div className='container' style={{ marginTop: 20 }}>
          <div className='row mbl-reverse'>
            <div className='col-md-6 col-sm-12 booking-details'>
              <div className="col col-xs-12 detail-container">
                <div className="row">
                  <div className="col-md-12 title">
                    VOUCHER DETAILS
                  </div>
                </div>
                {/* Booking reference */}
                <div className="row">
                  <div className="col-md-6 label-detail">
                    <b>Voucher Reference</b>
                  </div>
                  <div className="col-md-6 price-qty text-right">
                    <span className="label-detail text-left">
                      <b>{voucher_name}</b>
                    </span>
                  </div>
                </div>
                {/* Booking date */}
                <div className="row">
                  <div className="col-md-6 label-detail">
                    Voucher Date
                  </div>
                  <div className="col-md-6 price-qty text-right">
                    <span className="label-detail text-left">
                      {voucher_date}
                    </span>
                  </div>
                </div>
                {/* Park */}
                <div className="row">
                  <div className="col-md-6 label-detail">
                    Park
                  </div>
                  <div className="col-md-6 price-qty text-right">
                    <span className="label-detail text-left">
                      {voucher_park}
                    </span>
                  </div>
                </div>
                <br />
                {/* Total booking amount */}
                <div className="row">
                  <div className="col-md-6 label-detail">
                    Voucher Total With Bonus value
                  </div>
                  <div className="col-md-6 label-number">
                    {`$ ${voucher_total_amount}`}
                  </div>
                </div>
                {/* Amount paid */}
                <div className="row">
                  <div className="col-md-6 label-detail">
                    Amount Paid
                  </div>
                  <div className="col-md-6 label-number">
                    {`$ ${voucher_amount_paid}`}
                  </div>
                </div>
                <hr />
                {/* Amount outstanding */}
                <div className="row">
                  <div className="col-md-6 stotal">
                    Amount Outstanding
                  </div>
                  <div className="col-md-6 label-number">
                    {`$ ${voucher_amount_outstanding}`}
                  </div>
                </div>
              </div>
              <hr />

              <div className="col col-s-12 detail-container">
                  {this.creaditInfo()}

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}
