import React, { Component } from "react";
import StepperVoucher from "../Layout/StepperVoucher";
import { connect } from "react-redux";
import { fetchParks, fetchVoucher } from "../../actions/global";
import Park from "./park";
import VoucherDetail from "./voucherdetail";
import OrderSummary from "./ordersummary";
import Contact from "./contact";
import Payment from "./payment";
import { api_create_voucher, api_pay_voucher_check, api_pay_voucher, api_get_voucher_campaign } from "../../api/api";
import axios from "axios";
import ReactGA from 'react-ga';
import { numberSpinner, colorSetProperty, setGoogleFont, setHeadTag, setFooter, setTitle } from "../../helpers/helpers";
import WindcaveCard from "../OrderProcess/step6/WindcaveCard";
import { api_get_windcave_session_voucher } from "../../api/api";
import axiosRetry from 'axios-retry';

var timeSpinner = ""
class Voucher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      park_name: null,
      park_id: null,
      ParkCollapse: false,
      VoucherDetailCollapse: true,
      VoucherCollapse: false,
      ContactCollapse: false,
      PaymentCollapse: false,
      CheckoutCollapse: false,
      OrderSummaryCollapse: false,
      list_recepient: [],
      customer: null,
      payError: false,
      payErrorMsg: "",
      contactEnableSubmit: false,
      paymentEnableSubmit: false,
      parks: null,
      updatedFont: false,
      updatedHeadTag: false,
      updatedFooter: false,
      updatedTitle: false,
      windcaveLink: undefined,
      windcaveSession: null,
      productSelected: null,
      previousWindcaveSession: false,
      voucher_order_id: undefined,
      error: {
        errorCode: false,
        errorMsg: false,
        errorDetail: false,
        counter: 0,
      },
      campaign: [],
      isIOS: false,
      enableTicketOptions: false,
      terms_and_conditions_link: "",
      privacy_statement_link: "",
      contact_us_link: "" ,
      progress: 25
    };
  }

  UNSAFE_componentWillMount() {
    const reloadCount = sessionStorage.getItem('reloadCount');
    if(reloadCount < 1) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }
    this.toggleLoading(true)
  }

  componentDidUpdate() {
    // Call Customize
    if (this.state.parks) {
      this.customizeColor(this.state.parks);
      if (this.state.updatedTitle == false) {
        setTitle(this.state.parks.name);
        this.setState({ updatedTitle: true });
      }
      // - custom font -
      let snipFont = this.state.parks.t_google_font_snippets + ' <style> body {' + this.state.parks.t_font_to_use + '} </style>';
      if (snipFont && this.state.updatedFont == false) {
        setGoogleFont(snipFont);
        this.setState({ updatedFont: true })
      }
      // - head tag -
      let snipheadtag = this.state.parks.t_embed_head_tag;
      if (snipheadtag && this.state.updatedHeadTag == false) {
        setHeadTag(snipheadtag);
        this.setState({updatedHeadTag: true})
      }
      // - footer tag -
      let footerTag = this.state.parks.t_footer_voucher;
      if (footerTag && this.state.updatedFooter == false) {
        setFooter(footerTag);
        this.setState({ updatedFooter: true })
      }
    }
    // eslint-disable-next-line
    if (this.state.park_id != this.props.match.params.park_id) {
      this.setState({ park_id: this.props.match.params.park_id });
      this.props.fetchVoucher(this.props.match.params.park_id);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let state = { ...this.state };
    if (newProps.parks) {
      newProps.parks
        .filter(obj => obj.id === parseInt(this.state.park_id))
        .map(park => this.setState({
          park_name: park.name,
          parks: park,
          enableTicketOptions: park.enable_activity_option,
          terms_and_conditions_link: park.terms_and_conditions_link,
          privacy_statement_link: park.privacy_statement_link,
          contact_us_link: park.contact_us_link
        }));
    }
    setTimeout(() => {
      this.toggleLoading(false)
    }, 2000);
  }

  get_voucher_campaign = () => {
    const params = {
      jsonrpc: "2.0",
      method: "call",
      params: {
      }
    };
    axios
      .post(api_get_voucher_campaign, params, {
        headers: { "Content-Type": "application/json;charset=utf-8" }
      })
      .then(({ data }) => {
        if ("result" in data) {
          this.setState({campaign: data.result})
        }
      })
      .catch(({ err }) => {});
  }

  componentDidMount() {
    const { fetchParks, fetchVoucher } = this.props;
    this.get_voucher_campaign()
    fetchParks();
    fetchVoucher();
    if( this.props.match.params.park_id === "null" || !this.props.match.params.park_id ){
      this.ActionCollapseState("ParkCollapse")
    }
    this.setState({
      park_id: this.props.match.params.park_id
    });

    if (process.env.REACT_APP_ENV == 'production') {
      ReactGA.initialize('UA-145448805-1');
      ReactGA.pageview(window.location.href);
    }
  }

  customizeColor(park) {
    // Customize color
    const colors = {
        'color1': park.t_color1,
        'color2': park.t_color2,
        'color3': park.t_color3,
        'color4': park.t_color4,
    }
    colorSetProperty(colors);
  }

  postOrderApi = (url, param) => {
    let params = {
      jsonrpc: "2.0",
      method: "call",
      id: null,
      params: param
    };
    return axios.post(url, params, {
      headers: { "Content-Type": "application/json;charset=utf-8" }
    });
  };
  spinnerTimeout() {
    timeSpinner = setTimeout(
      function() {
        this.setState({isLoading: false})
      }
      .bind(this),
      numberSpinner()
    );
  }
  stopSpinnerTimout() {
    clearTimeout(timeSpinner)
  }

  toggleLoading(value) {
    this.setState({ isLoading: value });
    if (value) {
      this.spinnerTimeout()
    } else {
      this.stopSpinnerTimout()
    }
  }
  savePark = (park_id, park_name) => {
    let state = { ...this.state };
    state.park_id = park_id;
    state.park_name = park_name;
    this.collapseState(state, "VoucherCollapse");
    this.setState(state);
  };

  addVoucher = (data, is_continue) => {
    let state = { ...this.state };
    state.list_recepient.push(data);
    if (is_continue) {
      state = this.collapseState(state, "ContactCollapse");
    }
    this.setState(state);
  };

  ActionCollapseState = StateName => {
    let state = { ...this.state };
    state = this.collapseState(state, StateName);
    this.setState(state);
  };
  collapseState(state, StateName) {
    state.ParkCollapse = StateName === "ParkCollapse" ? true : false;
    state.CheckoutCollapse = StateName === "CheckoutCollapse" ? true : false;
    state.ContactCollapse = StateName === "ContactCollapse" ? true : false;
    state.VoucherCollapse = StateName === "VoucherCollapse" ? true : false;
    state.VoucherDetailCollapse =
      StateName === "VoucherDetailCollapse" ? true : false;
    state.PaymentCollapse = StateName === "PaymentCollapse" ? true : false;
    state.OrderSummaryCollapse =
      StateName === "OrderSummaryCollapse" ? true : false;
    state.WindcaveCardCollapse = StateName === "WindcaveCardCollapse" ? true : false;

   if(StateName === "ParkCollapse"){
      state.progress = 0;
    }else if(StateName === "VoucherDetailCollapse"){
      state.progress = 25;
    }else if(StateName === "ContactCollapse"){
      state.progress = 50;
    }else if(StateName === "WindcaveCardCollapse"){
      state.progress = 75;
    }


    return state;
  }

  payVoucherWindcave = () => {
    if (this.state.error.counter == 2) {
      window.location.reload()
    }
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let hasSafari = userAgent.match(/Safari/i);
    let hasOtherBrowser = /Chrome|CriOS|OPiOS|mercury|FxiOS|Firefox|GSA/i.test(userAgent)
    let isAppleVendor = /Apple/i.test(navigator.vendor);
    let iosDevice = /iPad|iPhone|iPod/.test(userAgent)
    let isMac = /Macintosh/.test(userAgent)
    let pay_method = ['card']
        let state = { ...this.state };
    state = this.collapseState(state, "WindcaveCardCollapse");
    this.setState(state);
    this.setState({
      previousWindcaveSession: this.state.windcaveSession
    });

    // Apple Pay only works on ios device and safari
    if ( (iosDevice && hasSafari && isAppleVendor && !hasOtherBrowser) || (isMac && hasSafari && !hasOtherBrowser) ) {
      this.setState({isIOS: true})
      pay_method.push('applepay');
    }
    // Google pay works on most of the android, browser and mac
    if (!iosDevice || ( isMac && hasOtherBrowser )) {
      pay_method.push('googlepay');
    }   

        this.toggleLoading(true);
    this.postOrderApi(api_get_windcave_session_voucher, {
      'total_amount': this.finalTotalPayment().toFixed(2),
      'voucher_code': this.state.voucher_ids,
      'voucher_order_id': this.state.voucher_order_id,
      'previous_windcave_session': this.state.previousWindcaveSession,
      'windcave_session': this.state.windcaveSession,
      'exp_from_record': true,
      'payment_method': pay_method,
    })
    .then(({ data }) => {
      if ("result" in data) {
        if (data.result === false) {
          window.location.reload()
        }


        const windcaveRef = data["result"]["links"].filter(link => link['method'] === 'REDIRECT')[0]['href']
        const wcSession = data["result"]["id"]
        if ("error" in data['result']) {
          this.setState({
            error: {
              errorCode: data['result']['error']['errorCode'],
              errorMsg: data['result']['error']['errorMsg'],
              errorDetail: data['result']['error']['errorDetail'],
              counter: this.state.error.counter + 1
            }
          });
          console.log(this.state.error.counter)
        }

        this.setState({
          windcaveLink: windcaveRef,
          windcaveSession: wcSession,
        });
      }
    })
  }

  finalTotalPayment() {
    let state = { ...this.state };
    let total_value = 0;
    let recipients =  
      state.list_recepient.map(recipient => {
        total_value = total_value + recipient.voucher_value;
      })
    return Math.max(0, total_value)
  }

  pay_voucher = cc_payment => {
    this.state.error.errorCode = false;
    this.state.error.errorMsg = false;
    this.state.error.errorDetail = false;

    let state = { ...this.state };

    this.toggleLoading(true);

    const client = axios.create({ baseURL: api_pay_voucher_check, headers: {'Content-Type': 'application/json'} });
    axiosRetry(client, { retries: 45, retryDelay: (retryCount) => {
      return retryCount * 750;
    } });
    let parameters = {
      voucher_order_id: this.state.voucher_order_id,
      windcave_session: this.state.windcaveSession,
    };

    client.get("/check", { params: parameters }).then( ({data}) => {
        if (data.success) {
          window.location.href =
          "/#/success/voucher/"+this.state.voucher_order_id;
        } else {
          this.setState({ payError: true });
          this.setState({ payErrorMsg: "Something went wrong." });
          this.toggleLoading(false);
        }
    });
    console.log(this.state.voucher_ids);
  };
  
  fetchNewSession = () => {
    if (this.finalTotalPayment() > 0) {
      this.postOrderApi(api_get_windcave_session_voucher, {
        'total_amount': this.finalTotalPayment().toFixed(2),
        'voucher_code': this.state.voucher_ids,
        'voucher_order_id': this.state.voucher_order_id,
        'previous_windcave_session': this.state.previousWindcaveSession,
        'windcave_session': this.state.windcaveSession,
        'exp_from_record': true,
        'full_page': true,
        'payment_method': ['applepay', 'card'],
      })
      .then(({ data }) => {
        if ("result" in data) {
          
          if (data.result === false) {
            window.location.reload()
          }

          const windcaveRef = data["result"]["links"].filter(link => link['method'] === 'REDIRECT')[0]['href']
          const currentWindcaveLink = this.state.windcaveLink;
          const session = data['result']['id']
          window.location.href = windcaveRef
        }
      })
    }
  }

  buyVoucher = customer => {
    console.log("BUY VOUCHER");
    let state = { ...this.state };
    state.customer = {
      email: customer.email,
      first_name: customer.first_name,
      name: customer.name,
      phone: customer.phone
    };
    this.setState({ contactEnableSubmit: false })
    this.postOrderApi(api_create_voucher, {
      customer: state.customer,
      recipient_list: this.state.list_recepient,
      park_id: this.state.park_id
    })
      .then(({ data }) => {
        if ("result" in data) {
          state = this.collapseState(state, "PaymentCollapse");
          state.voucher_ids = data["result"]["voucher_ids"];
          state.voucher_order_id = data["result"]["voucher_order_id"];
          console.log(state.voucher_ids)
          console.log(state.voucher_order_id);
          this.setState(state, ()=>{this.payVoucherWindcave()}); 
        } else {
          this.setState({ contactEnableSubmit: true })
          const error_message = data["error"]["data"]["message"];
          alert(error_message);
        }
      })
      .catch(err => {
        console.log(err);
        alert("there is a problem when Create Voucher");
      });
  };

  render() {
    let step = [
      true,
      this.state.list_recepient.length > 0
        ? true
        : false || this.state.VoucherDetailCollapse,
      this.state.customer ? true : false || this.state.ProfileCardCollapse,
      this.state.PaymentCollapse
    ];
    return (
      <div className={this.state.isLoading === true ? "loading" : ""}>
        <StepperVoucher step={step} park_id={this.state.park_id} parks={this.props.parks} />
        <div className="container voucher" style={{ marginTop: 20 }}>
          <div id="progress">
            <div className="progress-line progress-voucher">
              <div className={this.state.progress > 0 ? "progress-item finished" : this.state.progress === 0 ? "progress-item ongoing" : "progress-item"}>
                <div className="progress-title">Location</div>
                <div className="circle" onClick={()=>this.ActionCollapseState('ParkCollapse')}>1</div>
                <div className="circle-line" style={{borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px"}}></div>
              </div>                  
              <div className={this.state.progress > 25 ? "progress-item finished" : this.state.progress === 25 ? "progress-item ongoing" : "progress-item"}>
                <div className="progress-title">Gift Amount</div>
                <div className="circle" onClick={()=>this.ActionCollapseState('VoucherDetailCollapse')}>2</div>
                <div className="circle-line"></div>
              </div>
              <div className={this.state.progress > 50 ? "progress-item finished" : this.state.progress === 50 ? "progress-item ongoing" : "progress-item"}>
                <div className="progress-title">Contact Details</div>
                <div className="circle" onClick={()=> this.state.list_recepient.length > 0 && this.handleProgressClick('ContactCollapse')}>3</div>
                <div className="circle-line"></div>
              </div>
              <div className={this.state.progress > 75 ? "progress-item finished" : this.state.progress === 75 ? "progress-item ongoing" : "progress-item"}>
                <div className="progress-title">Checkout</div>
                <div className="circle">4</div>
                <div className="circle-line" style={{borderTopRightRadius: "8px", borderBottomRightRadius: "8px"}}></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <Park
                park_id={this.state.park_id}
                park_name={this.state.park_name}
                parks_all={this.props.parks}
                ParkCollapse={this.state.ParkCollapse}
                actionCollapse={this.ActionCollapseState}
                data={true}
                action={this.savePark}
              />
              <hr />
              <VoucherDetail
                cardClass="voucher-detail"
                VoucherDetailCollapse={this.state.VoucherDetailCollapse}
                actionCollapse={this.ActionCollapseState}
                park_name={this.state.park_name}
                enableTicketOptions={false}
                data={true}
                parks={this.state.parks}
                addVoucher={this.addVoucher}
                park_id={this.state.park_id}
                list_recepient={this.state.list_recepient}
                campaign={this.state.campaign}
                toggleLoading={this.toggleLoading.bind(this)}
              />
              <hr />
              <Contact
                cardClass="card-profile"
                ContactCollapse={this.state.ContactCollapse}
                actionCollapse={this.ActionCollapseState}
                parks={this.state.parks}
                action={this.buyVoucher}
                enableSubmit={this.state.contactEnableSubmit}
              />
              <hr />
              <WindcaveCard
                  type="voucher"
                  productSelected={this.state.productSelected}
                  park_id={this.state.park_id}
                  payError={this.state.payError}
                  cardClass="card-windcave"
                  totalAmount={this.finalTotalPayment()}
                  WindcaveCardCollapse={this.state.WindcaveCardCollapse}
                  PaymentCollapse={this.state.PaymentCollapse}
                  data={this.state.voucher_ids}
                  actionCollapse={this.ActionCollapseState}
                  getNewSession={this.fetchNewSession}
                  action={this.pay_voucher}
                  actionWindcave={this.payVoucherWindcave}
                  enableSubmit={this.state.paymentEnableSubmit}
                  parks={this.props.parks}
                  step="4"
                  windcaveLink={this.state.windcaveLink}
                  toggleLoading={this.toggleLoading.bind(this)}
                  error={this.state.error}
                  isIOS={this.state.isIOS}
                />

              <div id="footer-text"/>

            </div>
            <div className="col-md-1 col-sm-12"></div>
            <div className="col-md-5 col-sm-12">
              <OrderSummary
                cardClass="order-summary"
                OrderSummaryCollapse={this.state.OrderSummaryCollapse}
                actionCollapse={this.ActionCollapseState}
                customer={this.state.customer}
                list_recepient={this.state.list_recepient}
                park_name={this.state.park_name}
                campaign={this.state.campaign}
              />
            </div>
            <div id="footer-policy" style={{ marginTop: 25 }}>
                <a target="_blank" rel="noopener noreferrer" href={this.state.terms_and_conditions_link ? this.state.terms_and_conditions_link : ""}>Terms and Conditions</a> 
                <a target="_blank" rel="noopener noreferrer" href={this.state.privacy_statement_link ? this.state.privacy_statement_link : ""}>Privacy Statement</a> 
                <a target="_blank" rel="noopener noreferrer" href={this.state.contact_us_link ? this.state.contact_us_link : ""}>Contact Us</a> 
              </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    parks: state.parkReducer.data,
    vouchers: state.voucherReducer.data
  };
};
function mapDispatchToProps(dispatch, ownProps) {
  return {
    fetchVoucher: () => dispatch(fetchVoucher(ownProps.match.params.park_id)),
    fetchParks: () => dispatch(fetchParks())
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Voucher);
