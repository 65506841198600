import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./components/style.scss";
import "./components/custom.scss";
import "./components/Waiver/waiver.scss";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import { HashRouter, Route } from "react-router-dom";
import Base from "./components/OrderProcess/Base";
import Step1 from "./components/Backend/Step1"
import ConfirmPage from "./components/OrderFinish/ConfirmPage";
import ConfirmRequest from "./components/OrderFinish/ConfirmRequest";
import SuccessPage from "./components/Voucher/success/";
import Step2 from "./components/Backend/Step2";
import Voucher from "./components/Voucher"
import Waiver from "./components/Waiver"
import WaiverError from "./components/Waiver/waiverError"
import WaiverConfirmation from "./components/Waiver/waiverConfirmation"
import GroupBooking from "./components/GroupBooking/GroupBooking";
import Maintenance from "./components/Maintenace/Maintenace";
import PaymentLink from "./components/PaymentLink/Base";
import VoucherLink from "./components/VoucherLink/Base";
import PaymentStatus from "./components/PaymentLink/PaymentStatus";
import VoucherPaymentStatus from "./components/VoucherLink/VoucherPaymentStatus";
import WindcaveIFrame from "./components/OrderProcess/WindcaveIFrame";

if (process.env.REACT_APP_ENV == 'production') {
  // GTM initialize function
  (function(w,d,s,l,i) {
      w[l]=w[l]||[];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event:'gtm.js'
      });
      var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),
          dl=l!=='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', window.location.hash.split('/')[2] == 9 ? process.env.REACT_APP_GTM_ID_NL : process.env.REACT_APP_GTM_ID);
}


const store = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging' ?
    createStore(rootReducer, applyMiddleware(thunk, logger)) : createStore(rootReducer, applyMiddleware(thunk));

if (process.env.NODE_ENV === "production" && (window.location.hash === "#/" || window.location.hash === "")) {
  window.location = process.env.REACT_APP_WEB_HOME;
} else if (process.env.NODE_ENV === "staging" && (window.location.hash === "#/" || window.location.hash === "")) {
  window.location = process.env.REACT_APP_WEB_HOME;
  window.location.reload();
} else {
  const container = document.getElementById("root");
  const root = createRoot(container);
  root.render(
    <Provider store={store}>
      <HashRouter>
        <div>
          <Route exact path="/" component={App} />
          <Route path="/windcave-iframe/:windcave_status/booking/:booking_id" component={WindcaveIFrame}/>
          <Route path="/windcave-iframe/:windcave_status/voucher/:voucher_code" component={WindcaveIFrame}/>
          <Route path="/booking/:booking_id" component={ConfirmPage} />
          <Route path="/create-booking-request/" component={GroupBooking} />
          <Route path="/booking-request/" component={ConfirmRequest} />
          <Route path="/create-booking/step1" component={Step1} />
          <Route path="/create-booking/:park_id/:product_id/step2" component={Step2} />
          <Route path="/success/voucher/:id_voucher" component={SuccessPage} />
          <Route path="/payment_status/:booking_id" component={PaymentStatus}/>
          <Route path="/voucher_payment_status/:voucher_code" component={VoucherPaymentStatus}/>
          <Route path="/maintenance/" component={Maintenance}/>

          {/* order/0 is the default to unselecting park page */}
          <Route path="/voucher/:park_id" component={Voucher}/>
          <Route path="/order/:park_id" component={Base} />
          <Route path="/waiver_kiosk/:park_id/:booking_id/step" component={Waiver}/>
          <Route path="/waiver_kiosk/:park_id/:booking_id/confirmation" component={WaiverConfirmation}/>
          <Route path="/waiver_kiosk/:park_id/:booking_id/expired" component={WaiverError}/>
          <Route path="/booking_payment/" component={PaymentLink}/>
          <Route path="/voucher_payment/" component={VoucherLink}/>

          {/* <Route path="/order/:park_id" component={Maintenance} />
          <Route path="/booking_payment/" component={Maintenance}/>
          <Route path="/voucher_payment/" component={Maintenance}/>
          <Route path="/voucher/:park_id" component={Maintenance}/>
          <Route path="/waiver_kiosk/:park_id/:booking_id/step" component={Maintenance}/>
          <Route path="/waiver_kiosk/:park_id/:booking_id/confirmation" component={Maintenance}/>
          <Route path="/waiver_kiosk/:park_id/:booking_id/expired" component={Maintenance}/> */}
        </div>
      </HashRouter>
    </Provider>
  );
}

// Call the GTM url and put it on noscript tag on index.html

if (process.env.REACT_APP_ENV == 'production') {
  ReactDOM.render(
    <iframe src={`https://www.googletagmanager.com/ns.html?id=${window.location.hash.split('/')[2] == 9 ? process.env.REACT_APP_GTM_ID_NL : process.env.REACT_APP_GTM_ID}`}
      height="0" width="0" style={{display:'none',visibility:'hidden'}} title="gtm-id">
    </iframe>,
    document.getElementsByTagName('noscript')[0]
  )
}

// if (process.env.REACT_APP_ENV != 'production') {
  let script = document.createElement('script');
  script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyC-PPaEaWpe-boa_aU-XfuWnL97io21jpU&libraries=places&language=en-AU'
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
