import React, { Component } from "react";
import AbsaleCard from "./../AbsaleCard";
import {
  CardBody,
  Card,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Modal,
  ModalBody,
  Popover,
  PopoverBody
} from "reactstrap";
import {
  FaPlus,
} from "react-icons/fa";


class MainActivity extends Component {

  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      park_id: this.props.park_id,
      product_id: this.props.product_id,
      product_name: '',
      product_selected: this.props.productSelected,
      openpopoverActivitys: false,
      openpopoverTicketOptions: false,
      voucher:false,
      height: window.innerHeight, 
      width: window.innerWidth,
      modal_main : false,
      hide_button: false,
      classFocus: "ro",
      enableTicketOptions: [],
      ticketOption: null,
      ticketOptions: [],
      ticketOptionID: null,
      ticketCollapse: true,
      colors: {
        color1: null,
        color2: null,
        color3: null,
        color4: null,
      },
      canOpenTicketOption: true,
    };

    this.updateDimensions = this.updateDimensions.bind(this);
    this.openActivitys = this.openActivitys.bind(this);
    this.openTicketOptions = this.openTicketOptions.bind(this);
    this.handleChangeProduct = this.handleChangeProduct.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevProp.products_all !== this.props.products_all) {
      if (this.props.products_all.filter(obj => obj.company_id[0] === parseInt(this.props.park_id)).length > 1) {
        setTimeout(() => this.openActivitys(), 500)
      } else {
        this.setState({hide_button: true})
      }
    }
    if (prevProp.enableTicketOptions !== this.props.enableTicketOptions) {
      const park = this.props.parks_all.filter(obj => obj.id === parseInt(this.props.park_id))[0]
      this.state.colors.color1 = park.t_color1;
      this.state.colors.color2 = park.t_color2;
      this.state.colors.color3 = park.t_color3;
      this.state.colors.color4 = park.t_color4;
      setTimeout(() => this.insertTicketOptions(), 500)
    }

    if (!this.state.openpopoverActivitys) {
      if (this.state.classFocus !== "ro")  {
        this.setState({classFocus: "ro"})
      }
    }
    if (prevState.product_selected !== this.state.product_selected && this.state.product_selected != null) {
      this.setState({product_selected: this.state.product_selected, product_name: this.state.product_selected.name})
    }
    if (prevProp.productSelected === this.state.product_selected) {
      if (this.state.product_selected !== this.props.productSelected) {
        this.setState({product_selected: this.props.productSelected, product_name: this.props.productSelected.name})
      }
    }
  }

  insertTicketOptions () {
    this.props.toggleLoading(true)
    const products = this.props.products_all
      .filter(obj => obj.company_id[0] === parseInt(this.props.park_id))
      const ticketOptions_all = [...new Set(products.map(item => item.activity_option_id )).values()]
      let ticketOptions = [...new Map(ticketOptions_all.map((item) => [item["id"], item])).values()]
      ticketOptions = ticketOptions.filter(function(obj) {
        return obj.id
      })
      
      ticketOptions = ticketOptions.sort((a, b) => a.sequence > b.sequence ? 1:-1)
      this.setState({
        ticketOptions:ticketOptions
      })
      if (ticketOptions.length == 1){
        // this.changeTicketOptionSelected(ticketOptions[0].id, ticketOptions, false)
        ticketOptions.filter(obj => obj.id === parseInt(ticketOptions[0].id)).map(prod => {
          this.setState({
            ticketOption: prod.name,
            ticketOptionID: prod.id,
            product_id: null,
            product_name: null,
            product_selected: null,
            canOpenTicketOption: false,
          })
        })
        this.props.toggleLoading(false)
        setTimeout(() => this.openActivitys(), 200)

      }
      else if (ticketOptions.length > 1) {
        this.props.toggleLoading(false)
        setTimeout(() => this.openTicketOptions(), 500)
      }
      else {
        this.props.toggleLoading(false)
      }
  }

  updateDimensions() {
    this.setState({
      height: window.innerHeight, 
      width: window.innerWidth
    });
  }

  handleChangeProduct(id) {
    if (id === "voucher"){
      this.setState({ voucher: true});
    }else{
      this.setState({ product_id: id, voucher:false});      
    }
    
    this.changeProductSelected(id)
  }

  handleChangeTicketOptions(id, ticketOptions) {
    if (id === "voucher"){
      this.setState({ voucher: true});
    }else{
      this.setState({ product_id: id, voucher:false});      
    }
    this.changeTicketOptionSelected(id, ticketOptions)
  }

  changeProductSelected(id) {
    // eslint-disable-next-line
    this.props.products_all.filter(obj => obj.id === parseInt(id)).map(prod => {
      this.setState({product_selected: prod})
      this.productSubmit(false, prod)
    })
  }

  changeTicketOptionSelected(id, ticketOptions, openTicket=true) {
    // eslint-disable-next-line
    let ticket = "";
    ticketOptions.filter(obj => obj.id === parseInt(id)).map(prod => {
      ticket = prod.name
      this.setState({
        ticketOption: prod.name,
        ticketOptionID: prod.id,
        product_id: null,
        product_name: null,
        product_selected: null,
        ticketCollapse: !this.state.ticketCollapse,
      })
    })
    if (openTicket){
      this.openTicketOptions();
    }
    setTimeout(() => this.openActivitys(), 200)
  }

  openActivitys() {
    if (!this.props.enableTicketOptions || this.state.ticketOption) {
      this.setState({openpopoverActivitys: !this.state.openpopoverActivitys, classFocus: "when-focus"})
    }
  }

  openTicketOptions() {
    this.setState({openpopoverTicketOptions: !this.state.openpopoverTicketOptions, classFocus: "when-focus", ticketCollapse: !this.state.ticketCollapse})
  }

  toggle=()=> {
    this.setState(prevState => ({
      modal_main: !prevState.modal_main
    }));
  }

  showPopoverActivitys() {
    let product = null;

    if (this.props.enableTicketOptions) {
      product = this.props.products_all
      .filter(obj => obj.company_id[0] === parseInt(this.props.park_id) && obj.activity_option_id.id == this.state.ticketOptionID)
    }
    else {
      product = this.props.products_all
      .filter(obj => obj.company_id[0] === parseInt(this.props.park_id))
    }
  
    const activitys = product
    .map(prod => {
      return (
          <div className="no-underline" key={prod.id} >
            <div className="row margin-px">
              <div className="col-9 no-pad activity-text" onClick={() => this.handleChangeProduct(prod.id)}>
                <span>{prod.name}</span>
                <p>{prod.description}</p>
              </div>
              <div className="col-3 no-pad">
                <button className={`btn btn btn-plus-popover ${this.props.park_id != 9 ? 'bg-hover' : 'bg-hover2'}`} onClick={() => this.handleChangeProduct(prod.id)}>
                  {/* second activity price somehow magically become 0  */}
                  {/* From ${prod.price.toFixed(2)} */}
                  From ${prod.absale_product_price.reduce((prev, curr) => prev.price < curr.price ? prev : curr).price.toFixed(2)}
                  <i className="plus-silver-popover upper"></i>
                </button>
              </div>
            </div>
          </div>
      );
    });
    var poper = (
      <Popover
        trigger="legacy"
        isOpen={this.state.openpopoverActivitys}
        toggle={this.openActivitys}
        target="mainActivity"
        className="main-activity"
      >
        <PopoverBody className="popoverSize1">
          {activitys}
        </PopoverBody>
      </Popover>
    );

    var modal = (
      <Modal
      trigger="legacy" 
      className="main-activity"
      target="mainActivity"
      isOpen={this.state.openpopoverActivitys}
      toggle={this.openActivitys}
      >
        <ModalBody
        className="style-modal">
        {activitys}
        </ModalBody>
      </Modal>
  ); 
    return (
      <React.Fragment>
        {" "}
        {this.state.width < 1000 ? modal : poper}{" "}
      </React.Fragment>
    );
  }

  showPopoverTicketOptions() {
    if (this.state.ticketOptions.length == 0 && !this.props.enableTicketOptions) {
      this.insertTicketOptions();
    }
    else {
      const activitys = this.state.ticketOptions
        .map(prod => {
          return (
            <div className="no-underline" key={prod.id} >
              <div className="row margin-px mb-0">
                <div className="col-9 col-sm-9 no-pad activity-text" onClick={() => this.handleChangeTicketOptions(prod.id, this.state.ticketOptions)}>
                  <span>{prod.name}</span>
                </div>
              </div>

              <div className="row margin-px pt-0">
                <div className="col-9 col-sm-9 no-pad activity-text" onClick={() => this.handleChangeTicketOptions(prod.id, this.state.ticketOptions)}>
                  <p>{prod.description}</p>
                </div>
                <div className="col-3 col-sm-3" >
                  <button className="activity-plus-icon" onClick={() => this.handleChangeTicketOptions(prod.id, this.state.ticketOptions)} style={{width: "35px",
                          height: "35px"}}>
                    <b>+</b>
                  </button>
                </div>
              </div>
            </div>
          );
        });

      const activitys2 = this.state.ticketOptions
        .map(prod => {
          return (
            <div className="row no-underline" style={{justifyContent:"space-around"}} key={prod.id} >
              <div className={`col-10 ${this.props.park_id != 9 ? 'bg-hover' : 'bg-hover2'}`} onClick={() => this.handleChangeTicketOptions(prod.id, this.state.ticketOptions)}>
                <div className="activity-text" onClick={() => this.handleChangeTicketOptions(prod.id, this.state.ticketOptions)}>
                  <span className="">{prod.name}</span>
                  <p className="">{prod.description}</p>
                </div>
              </div>
              <div className="no-pad m-auto " style={{maxWidth:"10.66%"}}>
                  <button className="activity-plus-icon" onClick={() => this.handleChangeTicketOptions(prod.id, this.state.ticketOptions)} style={{width: "35px",
                          height: "35px"}}>
                      <FaPlus style={{fontSize:'12px'}}/>
                  </button>
              </div>
            </div>
          );
        });

      var poper = (
        <Popover
          trigger="legacy"
          isOpen={this.state.openpopoverTicketOptions}
          toggle={this.openTicketOptions}
          target="ticketOptions"
          className="main-activity"
          style={{paddingRight:"1rem"}}
        >
          <PopoverBody>
            {activitys2}
          </PopoverBody>
        </Popover>
      );

      var modal = (
        <Modal
        trigger="legacy" 
        className="main-activity"
        target="ticketOptions"
        isOpen={this.state.openpopoverTicketOptions}
        toggle={this.openTicketOptions}
        >
          <ModalBody
          className="style-modal">
          {activitys}
          </ModalBody>
        </Modal>
      ); 
      return (
        <React.Fragment>
          {" "}
          {this.state.width < 1000 ? modal : poper}{" "}
        </React.Fragment>
      );
    }
  }

  render() {
    const { numberCircle, cardStyle, cardClass } = this.props;
    const showPopoverActivitys = this.showPopoverActivitys();
    let { headCard, cardCollapse } = this.getCardDetail();
    
    if (!headCard) {
      headCard = (
        <React.Fragment>
          <div className="font-weight-bold">Main Activity</div>
          <div className="text-secondary font-weight-bold" />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <AbsaleCard
          hideButton={this.state.hide_button}
          cardStyle={cardStyle}
          numberCircle={numberCircle}
          headCard={headCard}
          step="2"
          cardClass={cardClass}
          cardCollapse={cardCollapse}
          collapse={this.props.MainActivityCollapse}
          data={this.props.data}
          actionCollapse={this.props.actionCollapse}
          name="MainActivityCollapse"
          parks={this.props.parks_all}
        />
        {showPopoverActivitys}
      </React.Fragment>
    );
  }
  
  productSubmit = (e, product) => {
    if (product) {
      this.setState({openpopoverActivitys: false})
      this.props.action(product, this.state.ticketOption);
    } else {
      this.setState({openpopoverActivitys: false})
      e.preventDefault();
      if (this.state.product_selected){
        this.props.action(this.state.product_selected, this.state.ticketOption);
      }
    }
  }

  getCardDetail() {
    const headCard = (
      <React.Fragment>
        <div className="font-weight-bold title">Main Activity</div>
        <div className="text-secondary font-weight-bold">
            {this.state.product_name}
        </div>
      </React.Fragment>
    );
    let valueTick = this.state.ticketOption
   
    const cardCollapse = (
      <Card>
        <CardBody>
          <Form
            onSubmit={e => this.productSubmit(e, false)}>
            <Row>
              <Col md={12}>
                <Row>
                  <Col sm={12}>
                    {
                      this.props.enableTicketOptions ?
                      <>
                        <div>
                          <div className="input-group-prepend">
                            <div className="input-group">
                              <Input
                              type="text"
                              onClick={this.openTicketOptions}
                              name="ticketOptions"
                              id="ticketOptions"
                              className="when-focus"
                              value={valueTick}
                              required
                              placeholder="Select Experience"
                              readOnly
                              autoComplete="off"
                              />
                              <i className="icon-on-input fa-user-alt float-right silver-color-icon">
                                <FaPlus onClick={this.openTicketOptions} />
                              </i>
                            </div>
                          </div>
                          { this.props.enableTicketOptions && this.state.canOpenTicketOption ? this.showPopoverTicketOptions() : ""}
                        </div>
                        <br/>
                      </>
                      : ""
                    }
                    <FormGroup>
                      <div className="input-group-prepend">
                        <div className="input-group">
                          <Input
                          type="text"
                          onClick={this.openActivitys}
                          name="mainActivity"
                          id="mainActivity"
                          className={this.state.classFocus}
                          required
                          placeholder="Select Activity"
                          readOnly
                          autoComplete="off"
                          />
                          <i className="icon-on-input fa-user-alt float-right silver-color-icon">
                            <FaPlus onClick={this.openActivitys} />
                          </i>
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );

    return { headCard, cardCollapse };
  }
}
export default MainActivity;
