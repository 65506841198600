import React, { Component } from "react";
import AbsaleCard from "./../../OrderProcess/AbsaleCard";
import {
  CardBody,
  Card,
  Button,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col
} from "reactstrap";

const SCREEN_WIDTH_LIMIT = 1000;

class Park extends Component {

    state = {
        height: window.innerHeight,
        width: window.innerWidth,
    };
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            park_id: null,
            product_id: this.props.product_id,
            product_selected: null,
            show_dropdown: false,
            selected_park: false,
            height: window.innerHeight,
            width: window.innerWidth,
        };
        this.handleChangeParks = this.handleChangeParks.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    updateDimensions() {
      this.setState({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }

    handleChangeParks(e) {
        this.setState({ park_id: parseInt(e.target.id), selected_park: e.target.innerHTML, show_dropdown: !this.state.show_dropdown});
    }
    parkSubmit(e) {
        e.preventDefault();
        var name = this.props.park_name
        name = this.props.parks_all.filter(val => val.id === parseInt(this.state.park_id)).map(obj => {
            return obj.name
        })
        if (!this.state.park_id || !name){
            return null
        }
        this.props.action(this.state.park_id, name);
        window.history.pushState(null, '', "/#/voucher/" + this.state.park_id);
        window.location.reload()
        this.props.actionCollapse("VoucherDetailCollapse")
    }
    render() {
        const { numberCircle, cardStyle, cardClass } = this.props;
        let { headCard, cardCollapse } = this.getCardDetail();
        if (!headCard) {
        headCard = (
            <React.Fragment>
                <div className="font-weight-bold">`Park Location`</div>
                <div className="text-secondary font-weight-bold" />
            </React.Fragment>
        );
    }

    return (
        <AbsaleCard
            cardStyle={cardStyle}
            numberCircle={numberCircle}
            headCard={headCard}
            step="1"
            cardClass={cardClass}
            cardCollapse={cardCollapse}
            collapse={this.props.ParkCollapse}
            data={this.props.data}
            actionCollapse={this.props.actionCollapse}
            name="ParkCollapse"
        />
    );
  }
    getCardDetail() {
        if (!this.props.parks_all) return "";
        let parks = this.props.parks_all.map(park_all => {
            return (
                <option
                    value={park_all.id}
                    key={park_all.id}
                >
                {park_all.name}
                </option>
            );
        });
        parks.unshift(<option
            value={null}
            key={null}
            >
            Select a park
            </option>);
        const list_parks = this.props.parks_all

        const parks2 = list_parks.map((park_all, index) => {
            return (
              <div className="row" key={park_all.id}>
                {
                  (index != 0) ?
                    (park_all.state != list_parks[index-1].state) ?
                      <>
                        <span className={"col-3 col-md-3 ml-2 mt-2 text-secondary font-weight-bold"}>{park_all.state}</span>
                      </>
                      : 
                      <span className={"col-3 col-md-3 ml-2 mt-2 text-secondary font-weight-bold"} style={{opacity:"0"}}>{park_all.state}</span>
                  :
                  <span className={"col-3 col-md-3 ml-2 mt-2 text-secondary font-weight-bold"}>{park_all.state}</span>
                }
                  <span onClick={this.handleChangeParks} id={park_all.id} className="col-8 col-md-8 ml-3 mt-2 park_selection" style={{fontWeight:"bold", padding:"0px"}}>{park_all.name}</span>
              </div>
            );
          });
        if (!this.state.park_id) {
            // eslint-disable-next-line
            this.state.park_id = this.props.park_id
        }
        const headCard = (
            <React.Fragment>
                <div className="font-weight-bold title">Park Location</div>
                <div className="text-secondary font-weight-bold">
                {this.props.park_name}
                </div>
            </React.Fragment>
        );

        const modalPark = (
            <Modal 
                backdrop={true}
                keyboard={false}
                centered={true}
                autoFocus={true}
                zIndex={2000}
                isOpen={this.props.ParkCollapse}
            >
                <ModalBody className="modal-warning">
                    <Form onSubmit={e => this.parkSubmit(e)}>
                        <Row style={{margin:"auto", width:"100%",padding:0}}>
                            <Col md={12} style={{margin:"auto", width:"100%",padding:0}}>
                                <Row style={{margin:"auto", width:"100%",padding:0}}>
                                    <Col sm={12} style={{margin:"auto", width:"100%",padding:0}}>
                                        <FormGroup>
                                        <div id="choose-park__selected" className={`${!this.state.show_dropdown ? 'select__selected park-showing' : 'select__selected'}`} style={{border:"#262b4d solid 1px", cursor:"pointer"}}>
                                            <div 
                                            onClick={() => {this.setState({ show_dropdown: !this.state.show_dropdown})}}
                                            className={"ml-3"}
                                            >
                                                {this.state.selected_park || this.props.park_name || "Select a Park"}
                                            </div>
                                        </div>
                                        <div 
                                            id="choose-park__list"
                                            className={`${!this.state.show_dropdown ? 'park-show fade-in' : 'park-collapse'}`}
                                            style={{border:"#262b4d solid 1px", borderTop:"0px", borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px"}} 
                                        >
                                            <div className="pt-2"></div>
                                            <div className="parks_list">
                                            {parks2}
                                            </div>
                                        </div>
                                        {/* <Input
                                            type="select"
                                            name="select"
                                            required
                                            onChange={this.handleChangeParks}
                                            value={this.state.park_id || undefined}
                                        >
                                            {parks}
                                        </Input> */}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Button className="btn" type="submit">
                            CONTINUE
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>
        )
        const cardCollapse = (
            <Card>
                <CardBody>
                    {this.state.width < SCREEN_WIDTH_LIMIT ? modalPark : ( 
                    <Form onSubmit={e => this.parkSubmit(e)}>
                        <Row style={{margin:"auto", width:"100%",padding:0}}>
                        <Col md={12} style={{margin:"auto", width:"100%",padding:0}}>
                            <Row style={{margin:"auto", width:"100%",padding:0}}>
                            <Col sm={12} style={{margin:"auto", width:"100%",padding:0}}>
                                <FormGroup>
                                <div id="choose-park__selected" className={`${!this.state.show_dropdown ? 'select__selected park-showing' : 'select__selected'}`} style={{border:"#262b4d solid 1px", cursor:"pointer"}}>
                                    <div 
                                    onClick={() => {this.setState({ show_dropdown: !this.state.show_dropdown})}}
                                    className={"ml-3"}
                                    >
                                        {this.state.selected_park || this.props.park_name || "Select a Park"}
                                    </div>
                                </div>
                                <div 
                                    id="choose-park__list"
                                    className={`${!this.state.show_dropdown ? 'park-show fade-in' : 'park-collapse'}`}
                                    style={{border:"#262b4d solid 1px", borderTop:"0px", borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px"}} 
                                >
                                    <div className="pt-2"></div>
                                    <div className="parks_list">
                                    {parks2}
                                    </div>
                                </div>
                                {/* <Input
                                    type="select"
                                    name="select"
                                    required
                                    onChange={this.handleChangeParks}
                                    value={this.state.park_id || undefined}
                                >
                                    {parks}
                                </Input> */}
                                </FormGroup>
                            </Col>
                            </Row>
                        </Col>
                        </Row>
                        <Button className="btn" type="submit">
                        CONTINUE
                        </Button>
                    </Form>)}
                </CardBody>
            </Card>
            );
        return { headCard, cardCollapse };
    }
}
export default Park;
