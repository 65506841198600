import React, { Component } from "react";
import { Collapse, Card } from "reactstrap";

let hasActive = false;
class AbsaleCard extends Component {
  state = { collapse: false };
  render() {
    // eslint-disable-next-line
    this.state.collapse = this.props.collapse;
    const { cardStyle, headCard, step, cardCollapse, cardClass } = this.props;
    const editHtml = this.state.collapse === false && this.props.data && this.props.step !== "4" && (
      <span
        onClick={() => this.props.actionCollapse(this.props.name)}
      >
        {" "}
        Edit{" "}
      </span>
    );
  

    //let { ...numberCircle } = this.props.numberCircle;
    //if (!this.props.data) numberCircle["background"] = "#424b5a";
    let circleClass = "";
    hasActive = this.state.collapse;
    
    // if (!this.props.data && hasActive) {
    //     circleClass = "circle step-ongoing";
    // }else if (!this.props.data && !hasActive && this.props.type != 'order'){
    //     circleClass = "circle step-dis";
    // }
    // else if ( this.props.data && (this.props.type != 'order') ) {
    //     circleClass = "circle step-finished";
    // }
    // else {
    //   circleClass = "";
    // }
    if (!this.props.data && hasActive) {
      circleClass = "circle step-ongoing";
    }else if (!this.props.data && !hasActive && this.props.type != 'order'){
        circleClass = "circle step-dis";
    }
    else if (!this.props.step || this.props.type == 'order') {
      circleClass = "";
    }
    else{
        circleClass = "circle step-finished";
    }
    return (
      <Card style={cardStyle} className={cardClass}>
        <div className="row">
          <div className="col-md-10 col-xs-10 head-card">
            {headCard}
          </div>
          {cardClass != 'card-windcave' ?
          <div
            className="edit-style"
          >
            {editHtml}
          </div>
          : ""
          }
        </div>

        <Collapse isOpen={this.state.collapse}>{cardCollapse}</Collapse>
      </Card>
    );
  }
}

export default AbsaleCard;
