import React, { Component } from 'react';
import {Button} from "reactstrap";
import { FaFacebook, FaHome } from "react-icons/fa";
import StepperMaintenace from "../Layout/StepperMaintenace";

export default class Maintenace extends Component {
  backtowebsite() {
    window.location.href = "https://treetopsadventure.com.au/" 
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    let state = { ...this.state };
    if (newProps.parks) {
      // eslint-disable-next-line
      newProps.parks.map(park => {
        if (park.id === parseInt(this.state.park_id)) {
          // eslint-disable-next-line
          state.park_name = park.name;
          state.parks = park;
        }
      });
    }
  }

    render() {
        let url = "https://treetopsadventure.com.au/contact/";

        if (parseInt(this.props.match.params.park_id) == 9) {
          url = "https://nextlevelpark.com.au/contact-us/"
        }

        return (
            <React.Fragment>
            <StepperMaintenace />
             <div className="container maintenace">
                 <div className="box pt-2">
                {/* <h4 className="confirm-thanks">
                  Our booking system is currently experiencing technical issues.
                 </h4> */}
                <h4 className="confirm-thanks">
                  Sorry, Our system is under maintenance. We will be back under a few hours
                 </h4>
                 <div className="confirm-text">

            Please{" "}
            <span>
              <a href={url}>
                <u>
                  contact
                </u>
              </a>
            </span>{" "}
               your preferred park.
            <br />

            <br />
              <Button className="btn btn-confirmation" onClick={() => this.backtowebsite()}>
                Go back to the website
              </Button>
            <br />
            <br />
            <a href="http://fb.com/treetopsadventure">
              <FaFacebook />
            </a>
            <a href="https://treetopsadventure.com.au/">
              <FaHome />
            </a>
          </div>
                 </div>
             </div>
            </React.Fragment>
        )
    }
}
