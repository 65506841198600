import React, { Component } from "react";
import Header from "./helper/Header";

var timeout_ID = null

class WaiverConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.match.params.bookingcode,
        };
    }

    UNSAFE_componentWillMount() {
        timeout_ID = setTimeout(function(){
            window.location.href = 'https://absale.treesadventure.com.au/waiver_kiosk/' + parseInt(sessionStorage.getItem('park_id'))
        }, 5000);
    }

    render() {
        let color1 = sessionStorage.getItem('color1')
        let parkID = sessionStorage.getItem('park_id')
        let parkName = sessionStorage.getItem('park_name')
        let waiverID = sessionStorage.getItem('waiver_id')
        let link = '#/waiver_kiosk/' + parkID + '/' + waiverID +'/step'
        return (
            <>
                <Header 
                    park={this.state.park}
                    park_id={parkID}
                />
                <div id="waiver" className="pt-4 pb-4">
                    <div className="container" style={{marginBlock:"20px"}}>
                        <div className="waiver-content">
                            <div className="col-md-12 mx-0">
                                <div className="row" style={{marginTop: "50px"}}>
                                    <div className="col-md-12 title-content text-center fs-subtitle mt-5">
                                        <h2><span className="confirm-title fs-title font-weight-bold">{parkName}</span></h2>
                                        <h1 style={{marginTop: "50px"}}>
                                            <span className="confirm-title font-weight-bold">Thank You!</span>
                                        </h1>
                                        <h4 style={{marginTop: "30px"}}>
                                            <span className="confirm-body font-weight-bold">WAIVER SUBMITTED!</span>
                                        </h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3"></div>
                                    <div className="col-sm-6 fs-subtitle">
                                        <div className="container-confirm-body text-center" style={{marginTop: "30px"}}>
                                            <span className="confirm-body font-weight-bold">We hope you enjoy your adventure!</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-3"></div>
                                </div>
                                <div className="row">
                                    <div className="mt-5 col-md-12 title-content text-center">
                                        <div 
                                            name="confirm_ok" 
                                            style={{width: "unset !important", background: color1, fontSize: "1.09375rem"}} 
                                            className="btn btn-lg btn-primary confirm-ok loading-spinner-modal" 
                                            onClick={() => {
                                                clearTimeout(timeout_ID)
                                                window.location.href = link
                                            }}
                                        >
                                            SIGN ANOTHER WAIVER
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default WaiverConfirmation;
