import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class StepperVoucher extends Component {
    render() {
        return (
            <div className="nav" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                    <a> TREETOPS ADVENTURE </a>
                </div>
                <div>
                    <a href='/'> Back to website </a>
                </div>
            </div>
        );
    }
}
export default StepperVoucher
