import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class Header extends Component {
    state = {
        park: null,
    }

    componentDidUpdate(prevProp, prevState) {
        if (prevProp.park !== this.props.park) {
            this.setState({park: this.props.park})
        }
    }

    getData() {
        let park = null

        if (this.state.park != null) {
            park = this.state.park
        }
        return {park}
    }

    render() {
        let { park } = this.getData()
        return (
            <div className="nav" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                    {park && park.logo_web ?
                        <a><img src={`data:image/png;base64,${park.logo_web}`} height="30" /></a> :
                        <a> TREETOPS ADVENTURE s</a>
                    }
                </div>
                <div>
                    {park && park.website ?
                        <a href={'https://absale.treesadventure.com.au/waiver_kiosk/' + park.id}>Back to website</a> :
                        <a href={'https://absale.treesadventure.com.au/waiver_kiosk/' + this.props.park_id}>Back to website</a>
                    }
                </div>
            </div>
        );
    }
}
export default Header
