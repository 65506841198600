import React, { Component } from "react";
import AbsaleCard from "./../AbsaleCard";
import {
  CardBody,
  Card,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input
} from "reactstrap";

// import "react-phone-input-2/dist/style.css";
let hasActive = false; 
let fields = [];

class ProfileCard extends Component {
  state = { name: "", phone: "", email: "", first_name: "", btnPay: false, 
  validate: { emailState: '', fnState: '', nameState: '', phoneState: ''} };
  
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.ProfileCardCollapse != this.props.ProfileCardCollapse) {
      this.setState({ btnPay: newProps.enableSubmit });
      let fields = [];
      let value = this.state;
      Object.keys(this.state).forEach(function(key) {
        fields[key] = value[key].length;
      });
      this.fieldCheck(fields);
    }
  }

  render() {
    const { cardStyle, numberCircle, cardClass, min } = this.props;
    const headCard = this.getheadCard();
    const cardCollapse = this.getCardDetail();

    return (
      <AbsaleCard
        cardStyle={cardStyle}
        cardClass={cardClass}
        numberCircle={numberCircle}
        headCard={headCard}
        step="4"
        cardCollapse={cardCollapse}
        collapse={this.props.ProfileCardCollapse}
        data={this.props.data}
        actionCollapse={this.props.actionCollapse}
        name="ProfileCardCollapse"
      />
    );
  }
  
  fieldCheck(fields){
    let { validate } = this.state;
      if(fields['first_name'] != null && fields['name'] != null && fields['email'] != null && fields['phone'] != null){
        if(fields['first_name'] > 0 && fields['name'] > 0 && fields['email'] > 0 && fields['phone'] > 5){
          if (validate.phoneState === 'has-success' && validate.emailState === 'has-success') {
            this.setState({ btnPay: true });
          }else {
            this.setState({ btnPay: false });
          }
        }
      }
  }
  
  validateEmail(e) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { validate } = this.state
      if (emailRex.test(e.target.value)) {
        validate.emailState = 'has-success'
      } else {
        validate.emailState = 'has-danger'
      }
      this.setState({ validate })
  }

  validBlur (e) {
    const { validate } = this.state
    validate.fnState = 'has-success'
    this.setState ({ validate })
  }

  validBlurName (e) {
    const { validate } = this.state
    validate.nameState = 'has-success'
    this.setState ({ validate })
  }

  validBlurPhone (e) {
    const phoneRegex = /^[().+\d -]{1,15}$/
    const { validate } = this.state
    if (phoneRegex.test(e.target.value) && e.target.value.length > 6) {
      validate.phoneState = 'has-success'
    } else {
      validate.phoneState = 'has-danger'
    }
    this.setState ({ validate })
  }

  handleChange(e) {
    fields[e.target.name] = e.target.value.length;
    this.setState({ [e.target.name]: e.target.value });
    this.fieldCheck(fields);
  }
  handleSubmit(event) {
    alert("An essay was submitted: " + this.state.value);
    // event.preventDefault();
  }

  getheadCard() {
    let detail = [];
    if (!this.props.ProfileCardCollapse) {
      detail.push(
        <div key={'head-card-1'} className="text-secondary font-weight-bold row col-md-12">
          <div className="col-md-5 div-flex">
            <div>{this.state.first_name + " " + this.state.name}</div>
          </div>
          {/* TODO */}
          <div className="col-md-7 small-text" style={{paddingRight: "#0px"}}>
            {this.state.email}
            <br />
            <div className="small-text">{this.state.phone !== 0 && "" + this.state.phone}</div>
          </div>
        </div>
      );
    }
    if(this.props.ProfileCardCollapse){
        hasActive = true;
    }
    return (
      <React.Fragment>
        <div className={hasActive ? "font-weight-bold title" : "font-weight-bold title head-dis" }>Contact details</div>
        {detail}
      </React.Fragment>
    );
  }

  
  getCardDetail() {
      let btn ="";
      if(this.props.totalParticipant <= 15){
          btn = (
            <Button className="btn" type="submit" disabled={!this.state.btnPay}>
              GO TO CHECKOUT
            </Button>
          );
      }else{
          btn = (    
            <div className="btn-send-request">
                <center>Group booking is sent as request only</center>
                <Button className="btn" type="submit" disabled={!this.state.btnPay}>
                  SEND YOUR REQUEST
                </Button>
                <div className="green-text">Our team will contact you to confirm your booking!</div>
            </div>
          );
      }
    const cardCollapse = (
      <Card>
        <CardBody className="cardbody-step3">
          <Form
            onSubmit={e => {
              e.preventDefault();
              this.props.action(this.state);
              this.setState({ btnPay: !this.state.btnPay });
            }}
          >
              <Label for="numberParticipant" className="g-color">
                {this.props.min > 5 ? "Please fill your personal info" : "You have 5 minutes to complete your purchase"}
              </Label>
              <FormGroup className="field-step3">
              <Input
                valid={ this.state.validate.fnState === 'has-success' }
                autoComplete="off"
                type="text"
                name="first_name"
                id="first_name"
                className="form-control step3"
                placeholder="First Name*"
                required
                value={this.state.first_name}
                onBlur={ (e) => {
                  this.validBlur(e)
                }}
                onChange={ (e) => {
                  // this.validateText(e)
                  this.handleChange(e)
                } }
              />
              <FormFeedback className="ftnamevalid" valid>
                First Name *
              </FormFeedback>
            </FormGroup>
            <FormGroup className="field-step3">
              <Input
                valid={ this.state.validate.nameState === 'has-success' }
                autoComplete="off"
                type="text"
                name="name"
                id="name"
                className="form-control step3"
                placeholder="Last Name*"
                required
                value={this.state.name}
                onBlur={ (e) => {
                  this.validBlurName(e)
                }}
                onChange={this.handleChange.bind(this)}
              />
              <FormFeedback className="namevalid" valid>
                Last Name *
              </FormFeedback>
            </FormGroup>
            <FormGroup className="field-step3">
              <Input
                autoComplete="off"
                type="email"
                name="email"
                id="email"
                className="form-control step3"
                placeholder="Email*"
                required
                value={this.state.email}
                onChange={ (e) => {
                  // force email to be lower case
                  e.target.value = e.target.value.toLowerCase();
                  this.validateEmail(e)
                  this.handleChange(e)
                } }
                valid={ this.state.validate.emailState === 'has-success' }
                invalid={ this.state.validate.emailState === 'has-danger' }
              />
              <FormFeedback className="emailvalid" valid>
                Email *
              </FormFeedback>
              <FormFeedback>
                Provide a valid email
              </FormFeedback>
            </FormGroup>
            <FormGroup className="field-step3 mb-0">
              <Input
                type="text"
                name="phone"
                id="phone"
                className="form-control step3"
                placeholder="Mobile*"
                required
                value={this.state.phone}
                onChange={ (e) => {
                  this.validBlurPhone(e)
                  this.handleChange(e)
                } }
                valid={ this.state.validate.phoneState === 'has-success' }
                invalid={ this.state.validate.phoneState === 'has-danger' }
              />
              <FormFeedback className="phonevalid" valid>
                Phone *
              </FormFeedback>
              <FormFeedback>
                Provide a valid mobile number
              </FormFeedback>
            </FormGroup>
            <p className="text-danger ml-1" style={{fontSize: '10px'}}>Use the '+country code' format for non-Australian numbers (e.g., USA: +1 234 567 8901)</p>
            {btn}
          </Form>
        </CardBody>
      </Card>
    );
    return cardCollapse;
  }
}

export default ProfileCard;
