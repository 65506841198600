import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { capitalize } from "../../../helpers/helpers";
import {
  FaAngleUp,
  FaAngleDown
} from "react-icons/fa";

class OrderSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderSummaryCollapse: true,
      height: window.innerHeight, 
      width: window.innerWidth
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this.setState({
      height: window.innerHeight, 
      width: window.innerWidth
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  getCurrentBonus(value){
    const rules = this.props.campaign.rules
    let bonus = 0;
    let next_rule = -1;
    if (rules) {
      for (let index = 0; index < rules.length; index++) {
        if (value >= rules[index].min_value && rules[index].max_value >= value){
          next_rule = index;
        }
      } 
      if (next_rule != -1) {
        bonus = rules[next_rule].bonus_value;
      }
    }
    return bonus
  }

  render() {
    let order = this.getOrderHtml();
    return order;
  }

  getVoucherValue(value, total) {
    return (
      <NumberFormat
        value={value}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
        renderText={value => <h3
          style={{'fontWeight': 'bold'}}>${total}.00</h3>}
      />
    )
  }

  getOrderHtml() {
    let recepient_html = [];
    let total = 0;
    let voucherName = "Treetops Adventure " + this.props.park_name;
    if (this.props.park_name) {
      let parkName = this.props.park_name.toLowerCase();
      voucherName = parkName.match(/next.*/) ? capitalize(parkName.split('park')[0]) : voucherName;
    }
    this.props.list_recepient.map(recepient => {
      total = total + recepient.voucher_value;
      let bonus = this.getCurrentBonus(recepient.voucher_value);
      let total_voucher_value = recepient.voucher_value + bonus;
      recepient_html.push(
        <div key={total} className="box-voucher col-md-12">
          <NumberFormat
            value={total_voucher_value}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            renderText={value => 
              <table className="text-white" style={{width:"100%"}}>
                <tbody>
                <tr>
                  <th>{recepient.recipient_name}</th>
                  <th>{bonus > 0 ? "Voucher Total With Bonus Value (+$" + bonus + ")": ""} </th>
                  <th style={{float:"right", fontSize:"25px"}}>{value}</th>
                </tr>
                <tr>
                  <th style={{fontSize:"12px"}}>{recepient.recipient_phone}</th>
                </tr>
                <tr>
                  <th style={{fontSize:"12px"}}>{recepient.recipient_email}</th>
                </tr>
                </tbody>
              </table>
              }
            
          />
          <div className="text-desc">
            <p>{voucherName}</p>
          </div>
        </div>
      );
    });
    const order = (
      <React.Fragment>
        {
          window.innerWidth < 775 ? (  
            <div className="footer-phantom">
                <div
                  className="order-sum-style-mobile sticky-footer"
                >
                  <div className={`pr-3 pl-3 ${this.state.orderSummaryCollapse ? '' : 'pb-2 pt-2'}`}>
                    <div className={`${this.state.orderSummaryCollapse ? 'ticket-collapse' : 'ticket-show fade-in'}`}>
                      <div className="row">
                        <div className="col-md-12 title">ORDER SUMMARY </div>{" "}
                      </div>
                      {recepient_html}
                    </div>
                  </div>
                  <div className="phantom-dropdown">
                    <div className={`pr-3 pl-3`}>
                      <div className="row pr-3 pb-3 pt-3">
                        <div className="col-md-2 col-2">
                          <div 
                            className="order-sum-arrow">
                            {
                              this.state.orderSummaryCollapse ?
                              <FaAngleUp 
                                style={{fontSize:"24px"}}
                                onClick={
                                  () => this.setState({ orderSummaryCollapse: !this.state.orderSummaryCollapse})}
                              />
                              :
                              <FaAngleDown 
                                style={{fontSize:"24px"}}
                                onClick={
                                  () => this.setState({ orderSummaryCollapse: !this.state.orderSummaryCollapse})}
                              />
                            }
                          </div>
                        </div>{" "}
                        <div className="col-md-4 col-4 stotal mb-0" style={{color:"white", fontSize:'20px', fontWeight:'bold'}}>Total</div>{" "}
                        <div className="col-md-6 col-6 pr-0 textnumber"  style={{color:"white", fontSize:'20px', fontWeight:'bold'}}>
                          {total !== 0 && (
                            <NumberFormat
                              value={total}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                              renderText={value => <h3>{value}.00</h3>}
                            />
                          )}
                        </div>{" "}
                      </div>{" "}
                    </div>
                  </div>
                </div>{" "}
              </div>
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="font-weight-bold title">ORDER SUMMARY</div>
                </div>
                <div className="col-md-12 pb-4">{recepient_html}</div>
                <br />
                <br />

                <div className="col-md-6 font-weight-bold">
                  {total !== 0 && "Total Price"}
                </div>
                <div className="col-md-6 total-style">
                  {total !== 0 && (
                    <NumberFormat
                      value={total}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      renderText={value => <h3>{value}.00</h3>}
                    />
                  )}
                </div>
              </div>
            )
        }
      </React.Fragment>
    );
    return order;
  }
}
export default OrderSummary;
